import React from 'react';
import { Container } from './footer.css';

const date = new Date();

const Footer = () => (
  <Container>
    <p className="to-upper">
      &copy; {date.getFullYear()} TraxxSolutions.com All Rights Reserved
    </p>
  </Container>
);

export default Footer;
