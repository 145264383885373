import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.header`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 50% 50%;

  a {
    color: #757575;
    font-weight: bold;
    transition: color 0.2s ease;
    text-decoration: none;
  }

  div.logo {
    max-width: 300px;
    padding-left: 3rem;
  }

  nav {
    ul {
      float: right;
      padding-right: 20px;
    }
    a {
      &:hover {
        color: red;
      }
    }
    ${MEDIA.TABLET`
      margin-top: 20px;
      margin-left: -20px;
      margin-right: -20px;
      background: #2c4251;
      padding: 1rem;
      ul {
        float: none;
        padding-right: 0;
      }
      a {
        color: white;
        &:hover {
          color: #aaaaaa;
        }
      }
    `};
  }
  ${MEDIA.TABLET`
    display: block;
    padding: 20px;
  `};
`;
