import styled from 'styled-components';

export const TopBar = styled.div`
  width: 100%;
  height: 30px;
  background: #2c4251;
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: auto;
  padding-top: 20px;
  background-color: #fff;
`;
