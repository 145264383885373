import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
import Header from 'components/header';
import Footer from 'components/footer';
import GlobalStyle from 'global.css.js';
import { TopBar, ContentWrapper } from './layout.css';

const Layout = ({ data, children }) => (
  <div>
    <GlobalStyle />
    <Head />
    <TopBar />
    <ContentWrapper>
      <Header
        logo={data.globalJson.logo}
        title={data.site.siteMetadata.siteTitle}
        description={data.site.siteMetadata.siteDescription}
      />
      {children}
    </ContentWrapper>
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
            siteDescription
          }
        }
        globalJson {
          logo {
            childImageSharp {
              fluid(maxHeight: 250, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
