import styled from 'styled-components';

export const Container = styled.footer`
  padding: 1rem;
  text-align: center;
  background-color: #2c4251;
  color: white;
  p.to-upper {
    margin-top: 1rem;
    text-transform: uppercase;
  }
}
`;
